import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { isEmpty} from 'lodash';
import { DISPOSITION_KEYS, DISPOSITION_TYPES_TEXT, LOCAL_STORAGE_KEYS, STORE_CLOSE, DATE_FORMATS, PDP_PAGE_URL_LENGTH, MENU, CATERING, ASAP_TIMING, ROUTE_URL, DAYS, ASAP} from 'common/constants/SharedConstants';
import { formatDateTime, getDayFromDate, getEpochTime, getFormatFromUnix, parseInvalidDateAndFormat, checkIsToday, getCurrentEpochTimeonTimeZone, getEpochTimeonTimeZone } from 'common/utilities/dayJsUtils';
import events from '@kfc-global/react-shared/analytics/Register';
import { getDataLayer, getDispositionTypeOnAPIFormat, isCateringDisposition, ParseJsonSafely, redirectToCateringPage, setSearchStoreByAtrribute, getDeliveryAddressData, getFullAddressDetails, isEmptyObject, isToday } from 'common/utilities/utils';
import { daysOrEverydayCheck } from 'organisms/SearchStore/AU/SearchStoreUtilities/SearchStoreUtils';
import { setUserParams } from '@kfc-global/react-shared/redux/Actions/AppStateAction';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { translateWithI18Next } from '@kfc-global/react-shared/i18n/i18N';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
const { DISPOSITION_NEW } = LOCAL_STORAGE_KEYS;
const { MMMM_D, YYYY_MM_DD, M_D_YYYY_h_mm_ss_A, h_mm_A, YYYY_MM_DD_hh_mm, YYYY_MM_DD_h_mm_A } = DATE_FORMATS
const {PICKUP} = DISPOSITION_KEYS ;
const checkUnavailablesSlots = (time, unavailibilitiesList) => {
  return unavailibilitiesList?.find(slot => {
    return time >= slot.from && time <= slot.to;
  });
};

export const getTimeInMillisec = date => {
  const getDay = new Date();
  return getDay.setDate(date.slice(-2));
};

export const getList = (st, et, interval, isASAP, isPastOrderAvailable, unavailibilities) => {
  if (!st || !et) {
    return false;
  }
  let unavailibilitiesList = [];
  if (unavailibilities?.length > 0) {
    for (let i = 0; i < unavailibilities?.length; i++) {
      const { from, to } = unavailibilities[i];
      unavailibilitiesList.push({
        from: new Date().setHours(Number(from?.split(':')[0]), Number(from?.split(':')[1]), 0, 0),
        to: new Date().setHours(Number(to?.split(':')[0]), Number(to?.split(':')[1]), 0, 0),
      });
    }
  }
  let timeSlots = [];
  const currentLocalMachineTime = new Date().getTime();
  let startTimeDate = new Date().setHours(Number(st?.split(':')[0]), Number(st?.split(':')[1]), 0, 0);
  let endTimeDate = new Date().setHours(Number(et?.split(':')[0]), Number(et?.split(':')[1]), 0, 0);
  if (isPastOrderAvailable) {
    endTimeDate += 86400000;
  }
  let nextDayMidnight = new Date().setHours(0, 0, 1) + (86400000 - 60000 * interval);
  let temp = startTimeDate;
  while (temp < endTimeDate) {
    if (unavailibilitiesList?.length > 0 && checkUnavailablesSlots(temp, unavailibilitiesList)) {
      temp += 60000 * interval;
      continue;
    }
    if (temp > nextDayMidnight) {
      timeSlots.push({
        timestr: new Date(temp).getHours() + ':' + new Date(temp).getMinutes(),
        selectedDay: 'Tomorrow',
      });
    } else {
      timeSlots.push({
        timestr: new Date(temp).getHours() + ':' + new Date(temp).getMinutes(),
        selectedDay: 'Today',
      });
    }
    temp += 60000 * interval;
  }

  timeSlots = timeSlots.map(item => {
    return { ...item, timestr: parseInvalidDateAndFormat(item.timestr, 'hh:mm', 'LT') };
  });
  if (currentLocalMachineTime > startTimeDate) {
    const splitIndex = timeSlots.findIndex(timeSlot =>  {
      const timeIn24HrsFormat = parseInvalidDateAndFormat( timeSlot.timestr, 'hh:mm A', 'HH:mm' ) 
      let epochTime;
      if(timeSlot.selectedDay === 'Today') {
        epochTime = new Date().setHours(Number(timeIn24HrsFormat?.split(':')[0]), Number(timeIn24HrsFormat?.split(':')[1]), 0, 0);
      } else {
        epochTime = new Date().setHours(Number(timeIn24HrsFormat?.split(':')[0]), Number(timeIn24HrsFormat?.split(':')[1]), 0, 0) + 86400000;
      }
      return (epochTime >= currentLocalMachineTime);
    });
    timeSlots = timeSlots.splice(splitIndex);
  }
  if (isASAP) {
    timeSlots.unshift({ timestr: 'ASAP', selectedDay: 'Today' });
  }
  return timeSlots;
};

export const getDateFromTime = (st, timeSelected) => {
  if (timeSelected === 'ASAP' || timeSelected?.selectedDay === 'Today') {
    return new Date();
  }
  return new Date(new Date().getTime() + 86400000);
};

 /* return disposition value based on different conditions*/
 export const getDispositionObj = (changeFlow, isAddressFlow, dispositionType) => {
  const { DISPOSITION_TYPE } = LOCAL_STORAGE_KEYS;
  const { PICKUP } = DISPOSITION_KEYS;
  const orderType = dispositionType
  ? dispositionType?.toUpperCase()
  : localStorage.getItem(DISPOSITION_TYPE)?.toUpperCase();
  const dispositionLocal = localStorage.getItem(DISPOSITION_TYPE);
  if (changeFlow && dispositionLocal) {
    return {
      dispositionValue: dispositionLocal?.toUpperCase(),
      dispositionName: DISPOSITION_TYPES_TEXT?.[dispositionLocal?.toUpperCase()],
    };
  } else if (isAddressFlow) {
    return {
      dispositionValue: PICKUP,
      dispositionName: DISPOSITION_TYPES_TEXT?.[PICKUP],
    };
  } else {
    return {
      dispositionValue: orderType,
      dispositionName: DISPOSITION_TYPES_TEXT?.[orderType],
    };
  }
};

export const parseDateList = (dateArr) => {
  let formattedDateArr = dateArr;
  return formattedDateArr?.length > 0 && formattedDateArr?.map(item => item?.date);
}

export const setScheduleDataOnChangeFlow = (changeFlow, userOrderStateDispatch, dispositionNew, SCHEDULE_ORDER_DATA) => {
  if(changeFlow){
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: {
        showScheduleOrderCatering: true,
        selectedStoreInfo: dispositionNew?.store,
        deliveryAddress: dispositionNew?.deliveryAddress,
        deliveryAddressLine: dispositionNew?.deliveryAddressLine,
        changeFlow: changeFlow
      },
    });
   }
}

export const storeUnavailableErrorAnalytic = async (disposition = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.storeClose?.name,
    {
      data: {
        event: STORE_CLOSE,
        disposition,
      },
    },
    getDataLayer,
  );
};

export const getStoreUnavailableErrorAnalytic = (storeUnavailableError, disposition, setStoreUnavailableAnalyticFlag) => {
  if (storeUnavailableError) {
    setStoreUnavailableAnalyticFlag(true);
    storeUnavailableErrorAnalytic(disposition?.toLowerCase());
  }
};
export const multiTimeSlot = (multiTimeSlotsPayload) => {
 const {element, startTime, endTime, currentEpochTime, interval, enableScheduleOrder = false, service ='', isAsapOrder = false, fromReorder = false} = multiTimeSlotsPayload;
  const changeScheduleMode = sessionStorage.getItem('changeScheduleMode') === 'true' ?? false;
  if (!startTime || !endTime) {
    return { date: { key: element, value: formatDateTime(element, MMMM_D), disabled: true }, timeSlots: [] };
  }
  const startEpochTime = getEpochTime(element + ' ' + startTime, YYYY_MM_DD_hh_mm);
  const endEpochTime = getEpochTime(element + ' ' + endTime, YYYY_MM_DD_hh_mm);
  let timeSlots = [];
  const addOneDay = enableScheduleOrder ? 0 : 24 * 60 * 60;
  let temp = startEpochTime;
  while (temp <= endEpochTime) {

    if (temp > currentEpochTime + addOneDay) {
      timeSlots.push({ key: temp, value: getFormatFromUnix(temp, h_mm_A) });
    }

    temp += 60 * interval;
  }
  const serviceType =  localStorage.getItem('serviceType');
  const isPickup = Boolean(serviceType?.toUpperCase() === PICKUP) ;
  Boolean(enableScheduleOrder && (fromReorder || changeScheduleMode) && isAsapOrder && isPickup) && timeSlots.unshift({ key: ASAP_TIMING, value: ASAP_TIMING}) ;
  return { date: { key: element, value: formatDateTime(element, MMMM_D), disabled: timeSlots?.length === 0 }, timeSlots };
}
export const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
/* returns a list of date & time slots for selected store */
export const getDateTimeList = (displayStoreInfo, interval = 30) => {
  const { summary = {} } = displayStoreInfo ?? {};
  const { timings = {}, timeZoneIANA: timeZone = '' } = summary;
  const { operatingHours = {}, futureDates = [] } = timings;

  let options = {
    timeZone: timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  formatter = new Intl.DateTimeFormat('en-US', options);
  const formatedFullDate = formatter?.format(new Date());
  const formatedDateTime = formatedFullDate?.split(',').join(' ');
  const currentEpochTime = getEpochTime(formatedDateTime, M_D_YYYY_h_mm_ss_A);
  return futureDates.map(element => {
    let parsedFutureDay = getDayFromDate(element, YYYY_MM_DD);
    let finalArr = daysOrEverydayCheck(operatingHours, parsedFutureDay);
    let startTime = '';
    let endTime = '';
    if (finalArr.length > 1) {
      const result = [];
      finalArr.forEach(item => {
        startTime = item?.availableHours?.from;
        endTime = item?.availableHours?.to;
        const multiTimeSlotsPayload = {
          element, 
          startTime, 
          endTime, 
          currentEpochTime, 
          interval
        }
        result.push(multiTimeSlot(multiTimeSlotsPayload));
      });
      const [obj, src] = result;
      return mergeWith(obj, src, customizer);
    } else {
      startTime = finalArr?.[0]?.availableHours?.from;
      endTime = finalArr?.[0]?.availableHours?.to;
      const multiTimeSlotsPayloadData = {
        element, 
        startTime, 
        endTime, 
        currentEpochTime, 
        interval
      };
      return multiTimeSlot(multiTimeSlotsPayloadData);
    } 
  });
};

export const dateTimeManipulation = (dateTimeManupulationObj) => {
  const {
    shouldShowScheduleOrder,
    store,
    setDateTimeArr,
    setSelectedDate,
    setSelectedTime,
    selectedDate,
    selectedTime,
    changeFlow,
    enableScheduleOrder = false,
    service = '',
    updateReorderDetails,
    fromReorder = false
  } = dateTimeManupulationObj;
  if (shouldShowScheduleOrder) {
    const dateTimeSlots = enableScheduleOrder
      ? getDateTimeListCA(store?.[0], enableScheduleOrder, service, fromReorder)
      : getDateTimeList(store?.[0]);
      const enabledDate = dateTimeSlots?.find(item => !item?.date?.disabled);
      const enableTimeObj = enableScheduleOrder ? {} : { ...enabledDate?.timeSlots?.[0]};
      fromReorder ? updateReorderDetails({dateTimeArr: dateTimeSlots}) : setDateTimeArr(dateTimeSlots);
      fromReorder ? isEmpty(selectedDate) && updateReorderDetails({selectedDate: enabledDate}) : setSelectedDate(enabledDate)
      fromReorder ? isEmpty(selectedTime) && updateReorderDetails({selectedTime: enableTimeObj}) : setSelectedTime(enableTimeObj);
    if (changeFlow) {
      const alreadySelectedDate = ParseJsonSafely(localStorage.getItem(DISPOSITION_NEW))?.date;
      const alreadySelectedTime = ParseJsonSafely(localStorage.getItem(DISPOSITION_NEW))?.time;
      const alreadySelectedEpochTime = getEpochTime(`${alreadySelectedDate} ${alreadySelectedTime}`, YYYY_MM_DD_h_mm_A);
      const defaultSlotEpochTime = enabledDate?.timeSlots?.[0]?.key;
      if (!fromReorder && alreadySelectedEpochTime > defaultSlotEpochTime) {
        setSelectedDate(dateTimeSlots?.find(item => item?.date?.key === alreadySelectedDate));
        setSelectedTime({ key: alreadySelectedEpochTime, value: alreadySelectedTime });
      }
    }
  }
};

export const setServiceTypeForPDP = (history, selectedDispositionValue) => {
  const params = history?.location?.pathname.split('/');
  if (params.length === PDP_PAGE_URL_LENGTH && (params.includes(MENU) || params.includes(CATERING))) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SERVICE_TYPE, getDispositionTypeOnAPIFormat(selectedDispositionValue));
  }
}

export const successCallbackHandler = async (params) => {
  const { dispatch, userOrderStateDispatch, dispositionNew, selectedDispositionValue, currentStoreInfoData, dateTimeObj, scheduleOrderData, history, redirectionOrder, userParams, dispositionType, fromflag, updatedBasketId } = params;
  const deliveryAddr = await getDeliveryAddressData(dispositionType, scheduleOrderData?.deliveryAddress);
  const deliveryAddressTxt = isEmptyObject(deliveryAddr) ? getFullAddressDetails(deliveryAddr) : scheduleOrderData?.deliveryAddress
  const {
    USER_LOCALIZED,
    SET_DISPOSITION,
    SCHEDULE_ORDER_TIME_DATA,
    SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    SET_REDIRECTION_PATH,
  } = START_ORDER_CONSTANTS;
  const {
    DISPOSITION_NEW,
    DISPOSITION_OLD,
    DATE_TIME_ORDER,
    SAVED_TIME_ORDER,
    SET_LOCATION
  } = LOCAL_STORAGE_KEYS;

    userOrderStateDispatch({
      type: SET_DISPOSITION,
      value: {
        oldvalue: dispositionNew,
        newValue: {
          type: selectedDispositionValue,
          store: currentStoreInfoData,
          date: dateTimeObj.date,
          time: dateTimeObj.time,
          interval: dateTimeObj.interval,
          selectedTime: dateTimeObj.selectedTime,
          deliveryAddress: deliveryAddressTxt,
          deliveryAddressLine: scheduleOrderData?.deliveryAddressLine,
          basketId: updatedBasketId,
        },
      },
    });
    localStorage.setItem(DISPOSITION_OLD, JSON.stringify(dispositionNew));
    localStorage.removeItem(DISPOSITION_NEW);
    localStorage.setItem(
      DISPOSITION_NEW,
      JSON.stringify({
        type: selectedDispositionValue,
        store: currentStoreInfoData,
        date: dateTimeObj.date,
        time: dateTimeObj.time,
        interval: dateTimeObj.interval,
        selectedTime: dateTimeObj.selectedTime,
        deliveryAddress: deliveryAddressTxt,
        deliveryAddressLine: scheduleOrderData?.deliveryAddressLine,
        basketId: updatedBasketId,
      }),
    );
    setServiceTypeForPDP(history,selectedDispositionValue);
    setSearchStoreByAtrribute();
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_TIME_DATA,
      value: { selectedDateTime: dateTimeObj },
    });
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_TIME_PRESERVE_TIME,
      value: { selectContentTime: !isCateringDisposition(dispositionType) || !fromflag ? ASAP_TIMING : dateTimeObj?.selectedTime },
    });
    localStorage.setItem(DATE_TIME_ORDER, JSON.stringify(dateTimeObj));
    localStorage.setItem(SAVED_TIME_ORDER, !isCateringDisposition(dispositionType) || !fromflag ? ASAP_TIMING : dateTimeObj?.selectedTime );
    dispatch(
      setUserParams({
        ...userParams,
        localized: true,
      }),
    );
    userOrderStateDispatch({
      type: USER_LOCALIZED,
      value: { isLocalized: true },
    });
    
  if (!fromflag) {
    if (localStorage.getItem(SET_LOCATION)) {
      localStorage.removeItem(SET_LOCATION);
    }
    else if (isCateringDisposition(dispositionType)) {
      if (history?.location?.pathname?.indexOf(ROUTE_URL.CATERING_PAGE) === -1) {
        redirectToCateringPage({ history });
      }
    }
    else if (redirectionOrder) {
      history.push({
        pathname: `${redirectionOrder}`,
      });
      userOrderStateDispatch({ type: SET_REDIRECTION_PATH, value: { redirectionOrder: '' } });
    } else if (history?.location?.pathname?.indexOf(ROUTE_URL.MENU_PAGE) === -1) {
      history.push({
        pathname: `${ROUTE_URL.MENU_PAGE}`,
      });
    }
  }
}

export const changedDeliveryAddress = (isAddressFlow,storeInfo,chooseConfig,changeFlow,searchedLocations,recentLocation,userOrderStateDispatch) => {
  const { SEARCH_ORDER_DATA, SCHEDULE_ORDER_DATA } = START_ORDER_CONSTANTS;
  if (isAddressFlow) {
    userOrderStateDispatch({
      type: SEARCH_ORDER_DATA,
      value: { showSearchStore: true, searchedPlaceNonLocalized: storeInfo.name[0].value },
    });
  } else if (searchedLocations) {
    userOrderStateDispatch({
      type: SEARCH_ORDER_DATA,
      value: { showSearchStore: true, searchedPlaceNonLocalized: recentLocation, configSearchScreen: chooseConfig },
    });
    localStorage.setItem(LOCAL_STORAGE_KEYS.CONFIG_SEARCH_SCREEN, chooseConfig);
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: {
        showScheduleOrderCatering: false,
        selectedStoreInfo: storeInfo,
        changeFlow: Boolean(changeFlow),
      },
    });
  }
}

export const getDateTimeListCA = (displayStoreInfo, enableScheduleOrder, service, fromReorder) => {
  const { summary = {} } = displayStoreInfo ?? {};
  const { timings = {}, timeZoneIANA: timeZone = '', orderingAttribute = {}} = summary;
  const { futureScheduled = [], asap } = timings;
  const {isAsapOrder} = orderingAttribute;
  const isAsapOrderNotToday = asap?.isAvailable;
  const options = {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    formatter = new Intl.DateTimeFormat('en-US', options);
  const formatedFullDate = formatter?.format(new Date());
  const formatedDateTime = formatedFullDate?.split(',').join(' ');
  const currentEpochTime = getEpochTime(formatedDateTime, M_D_YYYY_h_mm_ss_A);
  const futureScheduledData = futureScheduled?.filter(item => item?.periods?.length);
  enableScheduleOrder && fromReorder && isAsapOrder && !isAsapOrderNotToday && futureScheduledData.shift();
  return futureScheduledData?.map(element => {
    const { date, periods } = element;
    const parsedFutureDay = getDayFromDate(date, YYYY_MM_DD);
    const { interval, startEndTime } = periods?.[0];
    const { from: startTime, to: endTime } = startEndTime;
    const result = [];
    const multiTimeSlotsPayload = {
      element:date, 
      startTime, 
      endTime, 
      currentEpochTime, 
      interval, 
      enableScheduleOrder, 
      service, 
      isAsapOrder: isAsapOrderNotToday,
      fromReorder
    }
    result.push(multiTimeSlot(multiTimeSlotsPayload));
    const timeStamp = getTimeInMillisec(date)
    const ifTodayOrNot = checkIsToday(timeStamp,timeZone);
    const futureDay = ifTodayOrNot ? translateWithI18Next(TRANSLATE_MAPPING_KEY.TODAY) : DAYS[parsedFutureDay];
    result[0].date.value = futureDay + ' ' + result[0]?.date?.value;
    const [obj, src] = result;
    return mergeWith(obj, src, customizer);
  });
};
export const scheduledTimeError = (setHasError, hasError, errorMsg) => {
  setHasError({
    ...hasError,
    error: errorMsg,
    errormessage: translateWithI18Next(TRANSLATE_MAPPING_KEY.SCHEDULE_ORDER_ERROR),
  });
}

export const checkForScheduledTime = (enableScheduleOrder, timeZone, storeSelectedDate, storeSelectedTime) => {
  if (enableScheduleOrder && storeSelectedTime !== ASAP) {
    const currentEpochTime = getCurrentEpochTimeonTimeZone(timeZone);
    const formatedSelectedDateTime = `${storeSelectedDate} ${parseInvalidDateAndFormat(storeSelectedTime, DATE_FORMATS.H_MM_A_CAPS, DATE_FORMATS.HH_mm)}`
    const selectedEpochTime = getEpochTimeonTimeZone(formatedSelectedDateTime, timeZone);
    if (selectedEpochTime >= currentEpochTime) {
      return false;
    }
    return true;
  }
  return false;
}
